import React from "react";
import { useTranslation } from "react-i18next";

function Card(props: any) {
  const { t } = useTranslation("SelectMode");
  return (
    <div className="card card-bordered card-transition shadow-none h-100">
      <div className="card-body" onClick={() => props.handleClick(props.name)} style={{ cursor: "pointer" }} id={`${props.name}-mode`}>
        <div className="d-flex">
          <div className="flex-shrink-0">
            <img className="avatar text-primary" src={props.image} style={props.disabled && { opacity: 0.5 }} alt="Logo" />
            {props.new && <span className="badge bg-soft-success text-success d-block">{t("new")}</span>}
          </div>
          <div className="flex-grow-1 ml-3 my-auto">
            <div className="d-flex align-items-center">
              <h5 className="card-title mb-0" style={props.disabled && { opacity: 0.5 }}>
                {props.title}
              </h5>
            </div>
            <p className="card-text text-body small">{props.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function SelectMode(props: any) {
  const { t } = useTranslation("SelectMode");

  return (
    <div className="card mb-4">
      <div className="card-header d-flex">
        <h3 className="card-title">{t("selectTarget")}</h3>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-3 mb-3">
            <Card
              image="/assets/vendor/duotone-icons/com/com006.svg"
              title={t("accountFollowers.title")}
              name="followers"
              description={t("accountFollowers.description")}
              handleClick={props.onSelect}
            />
          </div>
          {/*<div className="col-md-3 mb-3">
            <Card
              image="/assets/vendor/duotone-icons/med/med001.svg"
              title={t("postLikes.title")}
              name="likers"
              description={t("postLikes.description")}
              handleClick={props.onSelect}
            />
          </div>*/}
          <div className="col-md-3 mb-3">
            <Card
              image="/assets/vendor/duotone-icons/com/com006.svg"
              title={t("accountFollowing.title")}
              name="following"
              new={true}
              description={t("accountFollowing.description")}
              handleClick={props.onSelect}
            />
          </div>
          <div className="col-md-3 mb-3">
            <Card
              image="/assets/vendor/duotone-icons/com/com012.svg"
              title={t("postComments.title")}
              name="commenters"
              description={t("postComments.description")}
              handleClick={props.onSelect}
            />
          </div>
          <div className="col-md-3 mb-3">
            <Card
              image="/assets/vendor/duotone-icons/gen/gen018.svg"
              title={t("location.title")}
              name="location"
              description={t("location.description")}
              handleClick={props.onSelect}
            />
          </div>
          <div className="col-md-3">
            <Card
              image="/assets/vendor/duotone-icons/abs/abs045.svg"
              title={t("hashtag.title")}
              name="hashtag"
              description={t("hashtag.description")}
              handleClick={props.onSelect}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectMode;
