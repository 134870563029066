import { faUsersCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STATUS_TYPES } from "../../utils/constants";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

function Stats({ analyses, analysesCount, loading }: any) {
  const { t } = useTranslation("Stats");

  function calculateStatus(analyses: any, status: any) {
    const count = analyses.filter((analysis: any) => analysis.status === status).length;
    const percentage = (count / analyses.length) * 100;
    return { count, percentage };
  }

  return (
    <div className="card mb-3 mb-lg-5">
      <div className="card-body">
        <div className="media align-items-md-center">
          {loading ? <Skeleton width={40} height={55} /> : <span className="display-3 text-dark mr-3">{analysesCount}</span>}
          <div className="media-body">
            <div className="row mx-md-n3">
              <div className="col-md px-md-4">
                <FontAwesomeIcon icon={faUsersCog} />
                <span className="d-block">{t("totalAnalyses")}</span>
              </div>
              <div className="col-md-9 col-lg-10 column-divider-md px-md-4">
                {/* Legend Indicators */}
                <div className="row justify-content-start mb-2">
                  {loading ? (
                    <>
                      {STATUS_TYPES.map(({ status, color }: any) => (
                        <div className="col-auto" key={status}>
                          <span className={`legend-indicator ${color}`} />
                          <Skeleton width={100} />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {STATUS_TYPES.map(({ status, color }: any) => {
                        const { count } = calculateStatus(analyses, status);
                        return (
                          <div className="col-auto" key={status}>
                            <span className={`legend-indicator ${color}`} />
                            {status.replace("_", " ")} ({count})
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
                {/* End Legend Indicators */}
                {/* Progress */}
                <div className="progress rounded-pill">
                  {loading ? (
                    <>
                      {STATUS_TYPES.map(({ status, color }: any) => (
                        <div
                          key={status}
                          className={`progress-bar ${color}`}
                          style={{ width: `25%` }}
                          aria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {STATUS_TYPES.map(({ status, color }: any) => {
                        const { percentage } = calculateStatus(analyses, status);
                        return (
                          <div
                            key={status}
                            className={`progress-bar ${color}`}
                            style={{ width: `${percentage}%` }}
                            aria-valuenow={percentage}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        );
                      })}
                    </>
                  )}
                </div>
                {/* End Progress */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Body */}
    </div>
  );
}

export default Stats;
